export const LOGIN_URL = "/login";
export const SET_LOGIN_URL = "/set-login";
export const SET_WM_LOGIN_URL = "/wm-login/:whcode/:magiccode/:deviceid/:web";
export const SET_RM_LOGIN_URL = "/rm-login/:usercode/:magiccode/:deviceid/:web";
export const DASHBOARD_URL = "/dashboard";
export const PURCHASE_LIST_URL = "/purchase-list";

// Inventory Route Url
export const PRODUCT_APPLICATIONS_URL = "/product-applications";
export const CREATE_PRODUCT_APLICATION_URL = "/create-applications";
export const CATEGORY_URL = "/category";
export const CREATE_CATEGORY_URL = "/create-category";
export const SUB_CATEGORY_URL = "/sub-category";
export const CREATE_SUB_CATEGORY_URL = "/create-sub-category";
export const PRODUCT_CREATION_URL = "/product-creation";
export const CREATE_PRODUCT_URL = "/create-product";
export const PRODUCT_GROUP_URL = "/product-group";
export const CREATE_PRODUCT_GROUP_URL = "/create-product-group";

// Stores Route Url
export const STOCK_INWARD_URL = "/stock-inward";
export const STOCK_LEVEL_URL = "/stock-level";
export const STORE_DETAILS_URL = "/stock-details";
export const STORE_INWARD_LOG_URL = "/stock-inward-log";
export const STORE_DETAILS_REPORT_URL = "/stock-details-report";
export const FRANCHISE_REPORT_DETAILS_URL = "/franchise-store-details";

// Stock Transfer Route Url
export const STOCK_TRANSFER_URL = "/stock-transfer";

// Warehouse Route Url
export const WAREHOUSE_URL = "/warehouse";
export const CREATE_WAREHOUSE_URL = "/create-warehouse";
export const WAREHOUSE_QUICK_URL = "/warehouse-quick-login";

// Franchise Route Url
export const FRANCHISE_USER_REPORT_URL = "/create-franchise";
export const FRANCHISE_USER_REPORT_BRAND = "/create-franchise-brand";
export const CREATE_FRANCHISE_USER_REPORT_URL = "/create-franchise-user-report";
export const FRANCHISE_ORDERSREPORT_URL = "/all-orders-report";
export const E_WAY_BILL_URL = "/e-way-bill-report";
export const FRANCHISE_STOCK_LEVEL_URL = "/franchise-stock-level";
export const E_INVOICE_CANCEL_URL = "/e-invoice-cancel";
export const E_INVOICE_DETAILS_URL = "/e-invoice-details";
export const PUR_INVOICE_DETAILS_URL = "/order-rejected";
export const ORDER_CANCELLED_URL = "/cancellation";
// export const ORDER_CANCELLED_URL = "/order-cancelled";
export const PUR_ORDER_DELIVERED_URL = "/order-delivered";
export const FRANCHISE_PENDING_DELIVERY_URL = "/pending-for-delivery";
export const FRANCHISE_DISPATCH_PENDING_URL = "/pending-for-dispatch";
export const FRANCHISE_DISPATCH_URL = "/pending-for-stores";
export const FRANCHISE_PENDING_ORDERS_URL = "/pending-for-accounts";
export const PO_APPROVALS_URL = "/warehouse-approvals";
export const PO_POSTING_URL = "/SO-approvals";
export const PO_PURCHASE_ORDER_URL = "/po-purchase-order";
export const USER_MANAGEMENT_URL = "/users";
export const RM_LOGIN_URL = "/rm-quick-login";
export const CREATE_USER_MANAGEMENT_URL = "/create-user";
export const REGION_MASTER_URL = "/region-master";
export const ADD_REGION_MASTER_URL = "/add-region-master";
export const CITIES_URL = "/cities";
export const ADD_CITIES_URL = "/create-cities";
export const UPDATE_CITIES_URL = "/edit-cities";
export const STATES_URL = "/states";
export const ADD_STATES_URL = "/create-states";
export const PINE_CODES_URL = "/pincodes";
export const ADD_PINCODES_URL = "/create-pincodes"
export const BOX_URL = "/box"
export const FRANCHISE_TYPE_REPORT_URL = "/franchise-type-report";
export const ADD_FRANCHISE_TYPE_REPORT_URL = "/add-franchise-type-report";
export const PURCHASE_ORDER_URL = "/SO-creation";
export const DISPATCH_LIST_URL = "/dispatch-list";
export const CREATE_DISPATCH_LIST_URL = "/create-dispatch";
export const COURIERS_URL = "/Couriers";
export const DEBIT_CREDIT_NOTES = "/debit-credit-notes";
export const ACCOUNT_LEDGER_REPORT = "/account-ledger-report";
export const DEBIT_CREDIT_NOTE_REPORT = "/debit-credit-note-report";
export const DEPOSIT_REPORT = "/deposit-report";
export const PAYMENT_APPROVAL = "/payment-approval";
export const CREDIT_NOTE_REQUEST = "/credit-note-request";
export const CREDIT_NOTE_REPORT = "/credit-note-report";
export const RETURN_AND_REPLACE_REPORT_LIST = "/return-and-replace-list";
export const RETURN_AND_REPLACE_REPORT = "/return-and-replace";
export const ERP_OUTSTANDING_REPORT = "/erp-outstanding-report";
export const ERP_OREDER_LOGS_LIST = "/erp-order-logs";
export const ERP_API_FAILURES_LIST = "/erp-api-failures";
export const FBA_ORDER_LIST = "/fba-orders";
export const FBA_ORDER_DETAILS_LIST = "/fba-orders-details";
export const RE_ASSIGN_WAREHOUSE_LIST = "/re-assign-warehouse";

//sales url
export const GST_DATA_REPORT = "/gst-data-report";
export const OUT_STANDING_DATA_REPORT = "/out-standing-report";

// CRM Refurbished urls
export const SETTINGS_REFURBISHED_REPORT = "/settings";
export const AGE_VALUE_REFURBISHED_REPORT = "/age-value";
export const POINT_VALUE_REFURBISHED_REPORT = "/point-list";
export const ADD_POINT_VALUE_REFURBISHED_REPORT = "/add-point";
export const EDIT_POINT_VALUE_REFURBISHED_REPORT = "/edit-point";
export const BRAND_VALUE_REFURBISHED_REPORT = "/brand-value";
export const REFURBISHED_REPORT_LIST = "/resellcycle-reports";
export const ADD_POINT_VALUE_SETTINGS = "/add-point-settings";
export const UPDATE_POINT_VALUE_SETTINGS = "/edit-point-settings";
export const AGE_VALUE_REPORT = "/age-value";
export const ADD_AGE_VALUE_REPORT = "/add-age-value";
export const UPDATE_AGE_VALUE_REPORT = "/edit-age-value";
export const RESALE_BRANDS_URL = "/resale-brands";
export const ADD_RESALE_BRANDS_URL = "/add-resale-brand";
export const UPDATE_RESALE_BRANDS_URL = "/edit-resale-brand";

// Api login urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const FILE_AUTH_URL = process.env.REACT_APP_BASE_URL + "file/";
export const LOGIN_API_URL = API_URL + "user/login";
export const LOGOUT_API_URL = API_URL + "user/logout";
export const UPLOAD_FILE_API_URL = API_URL + "file/upload";
export const PREVIEW_FILE_API_URL = API_URL + "file/view"
export const GET_MAGIC_CODE_API_URL = API_URL + "user/get_magic_code";
export const ORDER_ACCOUNT_APPROVAL_API_URL = API_URL + "order/ac_approval";
export const WAREHOUSE_EXPORT_DISPATCH_URL = API_URL + "warehouse/export_dispatch";
export const COURIER_EXPORT_COURIER_URL = API_URL + "courier/export_courier";
export const STORE_EXPORT_STOCK_ACCESSORIES_URL = API_URL + "store/export_stock_accessories";
export const WAREHOUSE_LOGIN_API_URL = API_URL + "user/wm_login";
export const RM_LOGIN_API_URL = API_URL + "user/rm_login";
export const GENERATE_FRAME_EXCEL_API_URL = API_URL + "store/generate_frame_excel";
export const ORDER_ST_APPROVAL_API_URL = API_URL + "order/store_approval";
export const SEND_REFURBISHED_PDF_API_URL = API_URL + "refurbished/send_refurbished_pdf";

// upload api url
export const IMPORT_STORE_API_URL = API_URL + "store/import_data";
export const IMPORT_ACCESSORIES_STORE_API_URL = API_URL + "store/import_data_accessories";
export const IMPORT_ORDER_API_URL = API_URL + "order/import_order";
export const GENERATE_INVOICE_API_URL = API_URL + "irisgst/generate_invoice";
export const IMPORT_FO_ORDERS_API_URL = API_URL + "order/import_fo_order";

// whatsapp api url
export const WHATSAPP_LIMECHAT_API_URL = API_URL + "order/limechat";

// invoice download view
export const MARKET_PLACE_INVOICE_API_URL = API_URL + "unicommerce/invoice_download_view";


// erp invoice & order retry API
export const ERP_RETRY_API_URL = API_URL + "erp/retry_erp_apis";
export const ERP_RETRY_PO_PR_API_URL = API_URL + "erp/retry_erp_po_pr_apis";